import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import TextSmall from "../Typography/TextSmall"
import { Box } from "../Box"

const Separator = styled.span`
  display: inline-block;
  margin: 0 8px;
  color:  var(--textDesc);
  font-size: ${props => props.theme.fontSize.xs};
  font-family: ${props => props.theme.fontFamily.default};

  &:before {
    content: "/";
  }
`

const Footer = () => (
  <Box>
    <TextSmall> Copyright &copy; 2020 - Avenir Zheng</TextSmall>
  </Box>
)

export default Footer
