import { createGlobalStyle } from "styled-components"
import '../../style/global.css'
import '../../style/source-code-pro.css'

export const Global = createGlobalStyle`
html {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  line-height: 1.5;
}


hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top-width: 1px;
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: inherit;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: solid;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
  padding: 0;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

*,
::before,
::after {
  box-sizing: inherit;
  border-color: #e2e8f0;
  border-style: solid;
  border-width: 0;
}

button {
  background: transparent;
  padding: 0;
}

button:focus {
  outline: 5px auto 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button,
[role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

body,
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

main,
details {
  display: block;
}

template,
[hidden] {
  display: none;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input::-ms-input-placeholder,
textarea::-ms-input-placeholder,
input::placeholder,
textarea::placeholder {
  color: #a0aec0;
}

html.borderbox *, html.borderbox *:before, html.borderbox *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}



body, button, input, select, textarea {
  font-family: PingFang SC, Lantinghei SC, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
  text-rendering: optimizelegibility;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 去除默认边框 */
fieldset, img {
  border: 0;
}

/* 块/段落引用 */
blockquote {
  position: relative;
  font-weight: 100;
  border-left: 1px solid var(--textDesc);
  padding-left: 1em;
  margin: 1em 3em 1em 2em;
}

@media only screen and ( max-width: 640px ) {
  blockquote {
    margin: 1em 0;
  }
}

/* Firefox 以外，元素没有下划线，需添加 */
acronym, abbr {
  border-bottom: 1px dotted;
  font-variant: normal;
  text-decoration: none;
}

/* 添加鼠标问号，进一步确保应用的语义是正确的（要知道，交互他们也有洁癖，如果你不去掉，那得多花点口舌） */
abbr {
  cursor: help;
}

/* 一致的 del 样式 */
del {
  text-decoration: line-through;
}

address, caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: 400;
}

/* 去掉列表前的标识, li 会继承，大部分网站通常用列表来很多内容，所以应该当去 */
ul, ol {
  list-style: none;
}

/* 对齐是排版最重要的因素, 别让什么都居中 */
caption, th {
  text-align: left;
}

q:before, q:after {
  content: '';
}

/* 统一上标和下标 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

:root sub, :root sup {
  vertical-align: baseline; /* for ie9 and other modern browsers */
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}


/* 默认不显示下划线，保持页面简洁 */
ins, a {
  text-decoration: none;
}

/* 专名号：虽然 u 已经重回 html5 Draft，但在所有浏览器中都是可以使用的，
 * 要做到更好，向后兼容的话，添加 class="typo-u" 来显示专名号
 * 关于 <u> 标签：http://www.whatwg.org/specs/web-apps/current-work/multipage/text-level-semantics.html#the-u-element
 * 被放弃的是 4，之前一直搞错 http://www.w3.org/TR/html401/appendix/changes.html#idx-deprecated
 * 一篇关于 <u> 标签的很好文章：http://html5doctor.com/u-element/
 */
u {
  text-decoration: underline;
}

/* 标记，类似于手写的荧光笔的作用 */
mark {
  background: #fffdd1;
  border-bottom: 1px solid #ffedce;
  padding: 2px;
  margin: 0 5px;
}

/* 代码片断 */
pre, code, pre tt {
  font-family: Courier, 'Courier New', monospace;
}

pre {
  padding: 1em 1.5em;
  display: block;
  -webkit-overflow-scrolling: touch;
}

/* 一致化 horizontal rule */
hr {
  border: none;
  border-bottom: 1px solid #cfcfcf;
  margin-bottom: 0.8em;
  height: 10px;
}

/* 底部印刷体、版本等标记 */
small,
  /* 图片说明 */
figcaption {
  font-size: 0.9em;
  color:var(--textDesc);
  text-align: center;
  line-height: 2;
}

strong, b {
  font-weight: bold;
}


/* 保证块/段落之间的空白隔行 */
 blockquote {
  margin-bottom: 1.2em
}

h1, h2, h3, h4, h5, h6 {
  font-family: PingFang SC, Verdana, Helvetica Neue, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
  font-weight: 100;
  line-height: 1.35;
  color:var(--textTitle);
}
h1{
  font-size: 2.5rem;
  /* 36px */
  line-height: 2.75rem;
  /* 40px */
  margin-bottom: 1.5rem;
  /* 24px */
  letter-spacing: -1.5px;
  font-weight: 400;
}
h2{
  font-size: 1.63rem;
  /* 24px */
  line-height: 2rem;
  /* 30px */
  margin-bottom: 0.5rem;
  /* 24px */
  letter-spacing: -1px;
  font-weight: 400;
}
h3{
  font-size: 1.17rem;
  /* 18px */
  line-height: 1.5rem;
  /* 24px */
  margin-bottom: 0.5rem;
  /* 24px */
  letter-spacing: -1px;
  font-weight: bold;
}

legend, caption {
  font-weight: inherit;
}



.gatsby-highlight {
  margin-bottom: ${props => props.theme.spacing[6]};
  margin-top: ${props => props.theme.spacing[6]};
}
`

export default Global
