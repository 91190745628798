export default {
  fontFamily: {
    default: [
      "PingFang SC", 
      "Lantinghei SC", 
      "Microsoft Yahei", 
      "Hiragino Sans GB", 
      "Microsoft Sans Serif", 
      "WenQuanYi Micro Hei", 
      "sans-serif",
    ].join(","),
    mono: [
      "Menlo",
      "Monaco",
      "Consolas",
      '"Liberation Mono"',
      '"Courier New"',
      "monospace",
    ].join(","),
  },
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.6rem",
    "3xl": "1.875rem",
    "4xl": "2rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  letterSpacing: {
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  spacing: {
    px: "1px",
    "0": "0",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "8": "2rem",
    "10": "2.5rem",
    "12": "3rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "32": "8rem",
    "40": "10rem",
    "48": "12rem",
    "56": "14rem",
    "64": "16rem",
  },
}
