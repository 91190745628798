import React from "react"
import { Link as RouterLink, graphql, useStaticQuery } from "gatsby"
import styled, { useTheme } from "styled-components"

import Logo from "../Logo"

import Link from "../Link"
import { Box } from "../Box"

const Separator = styled.span`
  display: inline-block;
  margin: 0 13px;
  color: var(--textDesc);
  font-size: ${props => props.theme.fontSize.lg};
  font-family: ${props => props.theme.fontFamily.default};

  @media (max-width: 374px) {
    margin: 0 4px;
    font-size: ${props => props.theme.fontSize.base};
  }

  &:before {
    content: "/";
  }
`

const MenuItem = styled(Link)`
  color: var(--textLink);
  opacity:0.6;
  font-size: ${props => props.theme.fontSize.lg};
  font-family: ${props => props.theme.fontFamily.default};
  font-weight: 400;

  @media (max-width: 414px) {
    font-size: ${props => props.theme.fontSize.base};
  }

  @media (max-width: 320px) {
    font-size: ${props => props.theme.fontSize.sm};
  }
`

const Navigation = () => {

  const theme = useTheme()

  return (
    <Box display="flex" alignItems="center" element="nav">
      <MenuItem
        element={RouterLink}
        to="/"
        aria-label="Home"
        activeStyle={{ opacity:1 }}
      >
        <Logo />
      </MenuItem>
      <Separator />
      <MenuItem
        activeStyle={{ opacity:1 }}
        element={RouterLink}
        to="/about"
      >
        关于
      </MenuItem>
      <Separator />
      <MenuItem
        activeStyle={{ opacity:1 }}
        element={RouterLink}
        to="/writings"
      >
        写作
      </MenuItem>
      <Separator />
      <MenuItem
        activeStyle={{ opacity:1 }}
        element={RouterLink}
        to="/speech"
      >
        演讲
      </MenuItem>
    </Box>
    
  )
}

export default Navigation
