import { ThemeToggler } from "gatsby-plugin-dark-mode"
import React, { useState } from "react"
import styled from "styled-components"

const ToggleWrapper = styled.div`
  position: absolute;
  z-index:3;
  right:${props => props.theme.spacing["4"]};
  top:40px;
  @media (min-width: 768px) {
    right:60px;
    top:60px;
  }
  @media (min-width: 1200px) {
    right:110px;
    top:110px;
  }
`
const Checkbox = styled.input`
    position: absolute;
    top: -5000px;
    height: 0;
    width: 0;
    opacity: 0;
    border: none;
    outline: none;
    &:active {
        position: absolute;
        top: -5000px;
        height: 0;
        width: 0;
        opacity: 0;
        border: none;
        outline: none;
    }
`;
const Label = styled.label`
    display: inline-block;
    position: relative;
    padding: 0px;
    margin-bottom: 20px;
    cursor: pointer;
`;
const DayLabel =  styled(Label)`
    &:empty { 
        margin-left: -10px;
    }
    &:before,
    &:after {
        content: '';
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        outline: none;
    }
    &:before {
        display: block;
        position: absolute;
        z-index: 1;
        line-height: 34px;
        text-indent: 40px;
        height: 16px;
        width: 16px;
        margin: 4px;
        border-radius: 100%;
        right: 17px;
        box-shadow: 5px 5px 0 0 #eee;
        bottom: 0px;
        transform: rotate(-45deg);
        background: transparent;
    }
    &:after {
        display: inline-block;
        width: 40px;
        height: 24px;
        border-radius: 16px;
        vertical-align: middle;
        margin: 0 10px;
        border: 2px solid white;
      }
      input[type=checkbox]:checked + &:before {
        right: 26px;
        background: gold;
        box-shadow: 0px 0px 0 0 #eee;
      }
      input[type=checkbox]:checked + &::after {
        border: 2px solid gold;
      }
`

const DarkModeToggle = () => {

  return (
    <>
      <ThemeToggler>
      {({ theme, toggleTheme }) => (
         
        <ToggleWrapper>
        
        {theme === "dark" ?
            (<div className="on"></div>) :
            (<div className="off"></div>)
        }
            <Checkbox type="checkbox" name="checkbox1" id="toggle-box-checkbox" 
            onChange={e =>
                toggleTheme(e.target.checked ? "light" : "dark")
            }
            checked={theme === "light"}
            />
           
            <DayLabel htmlFor="toggle-box-checkbox"></DayLabel>
            <Label htmlFor="toggle-box-checkbox"></Label>
        </ToggleWrapper>
        )}
      </ThemeToggler>
    </>
  )
}

export default DarkModeToggle